import { useEffect, useState } from "react";
import { useAtom } from 'jotai';
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import trackerAtom, { fetchtracker } from '../../../atoms/trackerAtom';
import { BiArrowBack } from "react-icons/bi"
import QcChecklists from "./qc_checklist";
import Container from "../../../components/container";
import { MdKeyboardArrowLeft } from "react-icons/md"
import { MdKeyboardArrowRight } from "react-icons/md"
import QcTrackerChecklist from "./qc_trackerChecklist";

function CheckBox({ name, onClick, border }) {
    return (
        <div>
            <button className={`w-[80%] p-1 m-4 pt-2 ml-6 border-2 ${border} flex flex-col justify-start items-start text-left rounded-xl h-full`} onClick={onClick}>
                <p className="font-medium pl-3 text-left text-wrap break-words">{name.split("-")[0]}-{name.split("-")[1]}<br />{name.split("-")[2]}</p>
            </button>
        </div>
    )
}


export const QcSideBar = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()

    const [showPanel, setShowPanel] = useState(true)
    const [clicked, setClicked] = useState()
    const [trackerName, setTrackerName] = useState('')

    const [listTracker] = useAtom(trackerAtom)
    const [, fetchTrackerInit] = useAtom(fetchtracker)

    useEffect(() => {
        init()
    }, [])

    const init = async () => {
        try {
            await fetchTrackerInit()
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <Container>
            <div className='flex h-full w-full'>
                {showPanel ?
                    <div className="border-r-2 border-gray-200 w-[22%]">
                        <div className="flex justify-between">
                            <BiArrowBack style={{ width: '25px', height: '25px' }} className="cursor-pointer mt-4 ml-5" onClick={() => navigate("/setup")} />
                            <div className="mt-4 mr-5">
                                <MdKeyboardArrowLeft className="cursor-pointer text-2xl" onClick={() => setShowPanel(false)} />
                            </div>
                        </div>
                        <div className="my-5 ml-5 text-xl font-bold">
                            QC Checklist
                        </div>
                        <button className="w-[80%] flex justify-between bg-black text-white cursor-pointer text-left rounded-xl w-72 py-5 ml-5" onClick={() => { setClicked(); navigate("/setup/QCChecklist-revamp") }}>
                            <p className="ml-5 mt-1">Manage Checklist</p>
                            <p className="mr-5 text-2xl">{">"}</p>
                        </button>
                        <div className='overflow-y-scroll mt-4 w-full' style={{ height: 'calc(100vh - 285px)' }}>
                            {listTracker.map((i, index) => {
                                return (
                                    <NavLink to={`${i?.id}`}>
                                        <CheckBox key={`ckd-${index}`} border={parseInt(params.id) === i.id ? "border-black" : "border-gray"} name={`${i.tracker_type}`} progress="Not Started" setup="Complete setup >" onClick={() => {
                                            setTrackerName({ type: i.tracker_type, id: i.id })
                                            setClicked(i.id)
                                        }} />
                                    </NavLink>
                                )
                            })}
                        </div>
                    </div>
                    :
                    null
                }
                <div className={`${showPanel ? "w-[78%]" : "w-full relative"} h-full`}>
                    {!showPanel && <MdKeyboardArrowRight className="absolute top-2 left-0 bg-gray-200 text-2xl cursor-pointer border" onClick={() => setShowPanel(true)}/>}
                    {location.pathname === "/setup/QCChecklist-revamp" ? <QcChecklists /> : <QcTrackerChecklist trackerName={trackerName} />}
                </div>
            </div>
        </Container>
    )
}
export default QcSideBar
