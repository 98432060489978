import Root from "./pages/misc/root";
import Dashboard from "./pages/dashboard/dashboard";
import ConstructionProgress from "./pages/checklist/constructionProgress/constructionProgress";
import StaffList from "./pages/manageStaff/staffList/staffList";
import WorkHour from "./pages/manageStaff/workHour/workHour";
import Reports from "./tabs/reports";
import Summary from "./pages/reports/summary/summary";
import ConstructionProgressSummary from "./pages/reports/summary/constructionProgressSummary";
import QualityCheckSummary from "./pages/reports/summary/qualityCheckSummary";
import WorkHoursSummary from "./pages/reports/summary/workHoursSummary";
import AccessRights from "./pages/accessRights/accessRights";
import Login from "./pages/login/login";
import ForgetPassword from "./pages/login/forgetPassword";
import FpasswordSuccess from "./pages/login/fpasswordSuccess";
import InitialStats from "./pages/reports/initialStats/initialStats";
import QCPunchlist from "./pages/reports/QCPunchlist/QCPunchlist";
import ActivityMileStones from "./pages/checklist/activityMileStone/activityMileStones";
import { useAtom } from "jotai";
import userAtom from "./atoms/userAtom";
import PlantList from "./pages/manageStaff/plantList/plantList";
import BlockList from "./pages/dashboard/blockList";
import IndividualAct from "./pages/manageStaff/workHour/individualAct";
import Settings from "./pages/Settings/index";
import CreatePassword from "./pages/login/createPassword";
import { useEffect } from "react";
import themeAtom from "./atoms/themeAtom";
import InviteUser from "./pages/inviteUser";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import ConstructionCreated from "./pages/checklist/constructionProgress/ConstructionCreated";
import AuditLogs from "./pages/checklist/auditLogs/auditLogs";
import PhotoView from "./pages/reports/QCPunchlist/photoView";
import QcSideBar from "./pages/checklist/QC-revamp/qc_sideBar";
import ImageViewerComp from "./components/imageViewerComp";
import PdfViewerComp from "./components/pdfViewerComp";
import Quality from "./tabs/quality";
import Lock from "./pages/quality/lockandunlock";
import ITRPage from './pages/quality/itranditc';
import NorthPowerBOM from "./pages/checklist/activityMileStone/northPowerBOM";
import ConstructionProgressTab from "./tabs/construction";
import QcTrackerChecklist from "./pages/checklist/QC-revamp/qc_trackerChecklist";
import StaffAndPlant from "./tabs/staffandplant";
import DashboardQc from "./pages/quality/dashboardQc";
import project_orgAtom, { fetchProjOrgAtom } from './atoms/permissionAtom'
import ForeCast from "./pages/reports/forecast/forecast";
import ZimmermanDashboard from "./pages/dashboard/zimmerDashboard";
import projectAtom, { fetchProjectAtom } from "./atoms/projectAtom";
import ErrorBoundary from "./utils/errorBoundary"
import Laydown from "./pages/dashboard/laydown";
import Scope from "./pages/dashboard/logistics/scope";
import LogisticsManagement from "./pages/dashboard/logistics/logisticsManagement";
import Inventory from "./pages/dashboard/logistics/inventory";
import ScopeInventory from "./pages/dashboard/logistics/scopeInventory";
import LaydownManagement from "./pages/dashboard/laydown-management/index";
import InventoryLog from "./pages/dashboard/laydown-management/inventoryLog";
import ScopeLaydown from "./pages/dashboard/laydown-management/scopeLaydown";
import ScopeD3 from "./pages/dashboard/laydown-management/scopeD3";
export function App() {

  const [theme] = useAtom(themeAtom)
  const [user] = useAtom(userAtom)

  const [listProjects] = useAtom(projectAtom)
  const [, setProjects] = useAtom(fetchProjectAtom)

  useEffect(() => {
    document.querySelector('html').setAttribute('data-theme', theme.theme);
  }, [theme.theme])

  useEffect(() => {
    if (user.loggedIn) {
      init()
    }
  }, [])

  let currentProject = listProjects.find(o => o.id === parseInt(localStorage.getItem("project_id")))

  const init = async () => {
    try {
      await setProjects()
    }
    catch (err) {
      console.log(err)
    }
  }

  function handleCheckZimmerman() {
    if (currentProject?.type) {
      return true
    }
    else {
      return false
    }
  }

  const [, fetchProjOrgPermission] = useAtom(fetchProjOrgAtom)
  const [getPermission] = useAtom(project_orgAtom)
  const permission_reference = {
    "general-Staff and Plant": [
      "/staff_and_plant",
      "/staff_and_plant/staffList",
      "/staff_and_plant/workHour",
      "/staff_and_plant/workHour/activity",
      "/staff_and_plant/plantList"
    ],
    "mech-Inventory management": ["/bill_of_material"],
    "general-Edit access rights": ["/access_rights"],
  }

  function qcCheck() {
    return currentProject?.project_settings?.qc
  }

  function laydownFlag() {
    return currentProject?.project_settings?.laydown_delivery
  }

  var routes_list = [
    {
      path: '/',
      element: <Root />,
      caseSensitive: true,
    },
    {
      path: '/dashboard',
      element: <BlockList />,
      caseSensitive: true,
    },
    {
      path: '/dashboard/summary/:id',
      element: handleCheckZimmerman() ? <ZimmermanDashboard /> : <Dashboard />,
      caseSensitive: true,
    },
    {
      path: '/dashboard/logistics-management',
      element: <LaydownManagement />,
      caseSensitive: true,
      children: [
        {
          path: ":id/inventory",
          element: <InventoryLog />
        },
        {
          path: ":id/scope",
          element: <ScopeLaydown />,
          children: [
            {
              path: ":block_id",
              element: <ScopeD3 />
            }
          ]
        }
      ]
    },
    // {
    //   path: '/dashboard/logistics-management',
    //   element: <LogisticsManagement />,
    //   caseSensitive: true,
    // },
    // {
    //   path: '/dashboard/logistics-management/:id',
    //   element: <ScopeInventory />,
    //   caseSensitive: true,
    // },

    // {
    //   path: '/dashboard/logistics-management/:id/scope',
    //   element: <Scope />,
    //   caseSensitive: true,
    // },
    {
      path: "/setup",
      element: <ConstructionProgressTab />,
      caseSensitive: true
    },
    {
      path: '/bill_of_materials',
      element: <ActivityMileStones />,
      caseSensitive: true,
    },
    {
      path: '/quality',
      element: <Quality />,
      caseSensitive: true,
    },
    {
      path: qcCheck() ? "/quality/qc_punchlist" : null,
      element: <QCPunchlist />,
      caseSensitive: true,
    },
    {
      path: '/quality/qc_lockandunlock',
      element: qcCheck() ? <Lock /> : <AuditLogs />,
      caseSensitive: true,
    },
    {
      path: qcCheck() ? '/quality/itranditc' : null,
      element: <ITRPage />,
      caseSensitive: true,
    },
    {
      path: qcCheck() ? '/quality/dashboard' : null,
      element: <DashboardQc />,
      caseSensitive: true
    },
    {
      path: qcCheck() ? '/setup/QCChecklist-revamp' : null,
      element: <QcSideBar />,
      caseSensitive: true,
      children: [
        {
          path: ":id",
          element: <QcTrackerChecklist />
        }
      ]
    },
    {
      path: '/setup/ConstructionProgress',
      element: <ConstructionProgress />,
      caseSensitive: true,
      children: [
        {
          path: ":id",
          element: <ConstructionCreated />
        }
      ]
    },
    {
      path: '/setup/bill_of_material',
      element: laydownFlag() ? <ActivityMileStones /> : <NorthPowerBOM />,
      caseSensitive: true,
    },
    {
      path: '/imageViewer',
      element: <ImageViewerComp />,
      caseSensitive: true
    },
    {
      path: '/pdfViewer',
      element: <PdfViewerComp />,
      caseSensitive: true
    },
    {
      path: '/staff_and_plant',
      element: <StaffAndPlant />,
      caseSensitive: true,
    },
    {
      path: '/staff_and_plant/staffList',
      element: <StaffList />,
      caseSensitive: true,
    },
    {
      path: '/staff_and_plant/workHour',
      element: <WorkHour />,
      caseSensitive: true,
    },
    {
      path: '/staff_and_plant/workHour/activity',
      element: <IndividualAct />,
      caseSensitive: true,
    },
    {
      path: '/staff_and_plant/plantList',
      element: <PlantList />,
      caseSensitive: true,
    },
    {
      path: '/reports',
      element: <Reports />,
      caseSensitive: true,
    },
    {
      path: '/reports/forecast',
      element: <ForeCast />,
      caseSensitive: true,
    },
    {
      path: '/reports/summary',
      element: <Summary />,
      caseSensitive: true,
      children: [
        {
          index: true,
          path: "constructionProgress",
          element: <ConstructionProgressSummary />
        },
        {
          path: qcCheck() ? "qualityCheck" : null,
          element: <QualityCheckSummary />
        },
        {
          path: "workHours",
          element: <WorkHoursSummary />
        }
      ],
      loader: () => ({
        data: {
          constructionProgress: [],
          qualityCheck: [],
          workHours: [],
        },
      }),
    },
    {
      path: '/reports/initialStats',
      element: <InitialStats />,
      caseSensitive: true,
    },
    {
      path: '/public/images/:id/:id',
      element: <PhotoView />,
      caseSensitive: true,
    },
    {
      path: '/quality/logsandchecklist',
      element: <AuditLogs />,
      caseSensitive: true,
    },
    {
      path: '/access_rights',
      element: <AccessRights />,
      caseSensitive: true,
    },
    {
      path: '/settings',
      element: <Settings />,
      caseSensitive: true,
    },
    {
      path: '/auth/reset',
      element: <CreatePassword />,
      caseSensitive: true,
    },
    {
      path: "/reset-password",
      element: <CreatePassword />,
      caseSensitive: true,
    },
    {
      path: '/inviteUser',
      element: <InviteUser />,
      caseSensitive: true,
    },
    {
      path: '*',
      element: <Root />,
    },
  ]

  const filter_path = (routes) => {
    for (const p of Object.keys(permission_reference)) {
      if (!getPermission[p]) {
        const exclude_list = permission_reference[p]
        routes = routes.filter(item => !exclude_list.includes(item.path));
      }
    }
    return routes
  }
  if (user.loggedIn) {
    return <RouterProvider router={createBrowserRouter([
      {
        errorElement: <ErrorBoundary />,
        children: filter_path(routes_list)
      },
    ])} />
  } else {
    return <RouterProvider router={createBrowserRouter([
      {
        path: "/",
        element: <Root />,
        caseSensitive: true,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/forgetPassword",
        element: <ForgetPassword />,
      },
      {
        path: "/forgetPassword/success",
        element: <FpasswordSuccess />,
      },
      {
        path: "/auth/reset",
        element: <CreatePassword />,
        caseSensitive: true,
      },
      {
        path: "/reset-password",
        element: <CreatePassword />,
        caseSensitive: true,
      },
      {
        path: "/inviteUser",
        element: <InviteUser />,
        caseSensitive: true,
      },
      {
        path: '/public/images/:id/:id',
        element: <PhotoView />,
        caseSensitive: true,
      },
      {
        path: "*",
        element: <Root />,
      },
    ]
    )} />
  }

}

export default App;