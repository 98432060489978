import React from 'react'
import { useState, useRef } from 'react'
import Container from '../../components/container'
import Modal from 'react-modal';
import { CgSoftwareDownload } from "react-icons/cg"
import { VscClose } from "react-icons/vsc"
import { BsBoxArrowUpRight } from "react-icons/bs"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { useNavigate, useParams } from 'react-router-dom';
import { BiArrowBack } from "react-icons/bi"
import { useAtom } from 'jotai';
import dashboardAtom, { fetchBomBlock, fetchSelectedTrackerBOM, fetchBlocks, fetchCount, fetchCountOverall, fetchSelectedActId, fetchBulkDashboardDataGet, fetchBulkDashboardDataPost, fetchMapSize, fetchCpTrackerRowAtom, fetchListSections, fetchCoordinates, fetchoverallCP } from '../../atoms/dashboardAtom';
import { useEffect } from 'react';
import activitiesAtom, { fetchActivitiesAtom } from '../../atoms/activitiesAtom';
import partsAtom, { fetchPartsAtom } from '../../atoms/partsAtom';
import { toast } from 'react-hot-toast';
import { Parser } from 'json2csv';
import projectAtom, { fetchProjectAtom } from '../../atoms/projectAtom';
import produce from 'immer';
import { BlockMap, ActivitySelectBox } from '@product-fusion/surya-fw';
import roundOff from '../../utils/roundOff';

const SelectBoxView = ({ onChange, flag }) => {

  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState("Layout")
  const dropdownRef = useRef(null)


  const handleFocus = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleOnClick = (id) => {
    setSelectedItem(id)
    if (id === "Layout") {
      onChange("pier")
    }
    if (id === "Pier Map") {
      onChange("part number")
    }
    if (id === "TT Map") {
      onChange("torque tube")
    }
    if (id === "BHA & SG Map") {
      onChange("bha")
    }
    setIsOpen(false)
  }

  return (
    <div className='relative flex mt-[-2px]' ref={dropdownRef}>
      <div>
        <input
          type='text'
          className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white pr-4 pl-2 mt-[-5px] btn btn-sm focus:outline-none'
          style={{ textTransform: "capitalize", width: "120px" }}
          onClick={handleFocus}
          value={selectedItem}
          readOnly
        />
        {isOpen && (
          <div className='absolute z-10 bg-white shadow border border-gray-300 rounded-xl' style={{ width: "120px" }}>
            <ul className='py-2 max-h-60 overflow-y-auto'>
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("Layout")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>Layout</p>
                </span>
              </li>
              {/* {flag?.pier_map === true ? */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("Pier Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>Pier Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
              {/* {flag?.tt_map === true ? */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("TT Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>TT Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
              <li className='cursor-pointer pl-2 hover:bg-gray-200' style={{ textTransform: "capitalize" }} onClick={() => handleOnClick("BHA & SG Map")}>
                <span className='mx-2 py-2 flex'>
                  <p className=''>BHA & SG Map</p>
                </span>
              </li>
              {/* :
                null
              } */}
            </ul>
          </div>
        )}
      </div>
      {isOpen ?
        <MdKeyboardArrowUp className='absolute right-2 top-1.5 text-sm text-white cursor-pointer' onClick={handleFocus} />
        :
        <MdKeyboardArrowDown className='absolute right-2 top-1.5 text-sm text-white cursor-pointer' onClick={handleFocus} />
      }
    </div>
  )
}

function Dashboard2() {

  const [loading, setLoading] = useState(false)
  const [countLoading, setCountLoading] = useState(false)
  const [bomLoading, setBomLoading] = useState(false)

  const [showDescription, setShowDescription] = useState()
  const [openTrackerModal, setTrackerModal] = useState(false)
  const [showTT, setShowTT] = useState("pier")
  const [selectAll, setSelectAll] = useState(false)
  const [clearSt, setClearSt] = useState(false)
  const [sectionSelect, setSectionSelect] = useState([])
  const [prevSection, setPrevSection] = useState(null)
  const [hightLightTracker, setHightLightTracker] = useState([])
  const [trackerSelect, setTrackerSelect] = useState([])
  const [prevTracker, setPrevTracker] = useState(null)
  const [showProgress, setShowProgress] = useState(true)
  const [trackerList, setTrackerList] = useState([])
  const [d3xScaleyScale, setD3xScaleyScale] = useState({})

  const _ = require("lodash")

  const [listblocks] = useAtom(dashboardAtom)
  const [listId] = useAtom(activitiesAtom)
  const [listPartsAtom] = useAtom(partsAtom)
  const [, setFetchoverallCP] = useAtom(fetchoverallCP)
  const [, setFetchParts] = useAtom(fetchPartsAtom)
  const [, setFetchBomBlock] = useAtom(fetchBomBlock)
  const [, setfetchActivitiesAtom] = useAtom(fetchActivitiesAtom)
  const [, setFetchSelectedTrackerBOM] = useAtom(fetchSelectedTrackerBOM)
  const [, setfetchBlocks] = useAtom(fetchBlocks)
  const [, setTrackerProgress] = useAtom(fetchCpTrackerRowAtom)
  const [, setDeployCount] = useAtom(fetchCount)
  const [, setSectionList] = useAtom(fetchListSections)
  const [, setMapSize] = useAtom(fetchMapSize)
  const [, setDeployOverallCount] = useAtom(fetchCountOverall)
  const [, setActivityId] = useAtom(fetchSelectedActId)
  const [projectsList] = useAtom(projectAtom)
  const [, setFetchProjects] = useAtom(fetchProjectAtom)
  const [, setFetchBulkGet] = useAtom(fetchBulkDashboardDataGet)
  const [, setFetchBulkPost] = useAtom(fetchBulkDashboardDataPost)
  const params = useParams();

  const [overallProgress, setOverallProgress] = useState(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
  const [selectedRow, setSelectedRow] = useState([])

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (overallProgress !== "All" && overallProgress !== "None Selected") {
      handleDeployCounts()
    }
  }, [listId?.construction])

  const handleDeployCounts = async () => {
    try {
      if (listId?.construction) {
        setLoading(true)
        setActivityId(localStorage.getItem("selected-activity"))
        await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
      }
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
    finally {
      setLoading(false)
    }
  }

  function handleCheckZimmerman() {
    if (projectsList?.type) {
      return true
    }
    else {
      return false
    }
  }

  function qcCheck() {
    let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))
    return project_name?.project_settings?.qc
  }

  let project_name = projectsList.find(p => p.id === parseInt(localStorage.getItem("project_id")))

  const init = async () => {
    try {
      setLoading(true)
      setFetchoverallCP()
      setCountLoading(true)
      setFetchBulkGet({ id: params.id })
      setfetchActivitiesAtom()
      setFetchBulkPost({ id: params.id })
      setfetchBlocks()
      setMapSize({ id: params.id })
      setFetchParts()
      setFetchProjects()
      setTrackerProgress({ block_id: params.id })
      if (localStorage.getItem("selected-activity") !== "None Selected") {
        if (localStorage.getItem("selected-activity") !== "All" && localStorage.getItem("selected-activity") !== null) {
          // setActivityId(localStorage.getItem("selected-activity"))
          // await setDeployCount({ activity_id: localStorage.getItem("selected-activity"), part_id: listId?.construction[localStorage.getItem("selected-activity")]?.part_id, block_id: params.id, tracker: [] })
        }
        else {
          setActivityId("All")
          await setDeployOverallCount({ block_id: params.id, tracker: [] })
        }
      }
      if (localStorage.getItem("selected-activity") === "None Selected") {
        setActivityId("None Selected")
      }
      setOverallProgress(localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : "All")
    }
    catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    } finally {
      setLoading(false)
      setCountLoading(false)
    }
  }

  function findMinMax(nestedData) {
    const allCoords = [];

    // Flattening the nested data
    Object.values(nestedData).forEach(coordsArray => {
      coordsArray.forEach(coord => {
        allCoords.push(coord);
      });
    });

    const latitudes = allCoords.map(coord => coord.latitude);
    const longitudes = allCoords.map(coord => coord.longitude);

    return {
      minimum_latitude: Math.min(...latitudes),
      maximum_latitude: Math.max(...latitudes),
      minimum_longitude: Math.min(...longitudes),
      maximum_longitude: Math.max(...longitudes)
    };
  }

  function hasNullValues(data) {
    if (data) {
      const values = [
        data?.piers?.minimum_latitude,
        data?.piers?.maximum_latitude,
        data?.piers?.minimum_longitude,
        data?.piers?.maximum_longitude,
        data?.tt?.minimum_latitude,
        data?.tt?.maximum_latitude,
        data?.tt?.minimum_longitude,
        data?.tt?.maximum_longitude
      ];

      return values.some(value => value === null);
    }
  }

  useEffect(() => {
    if (listblocks?.mapSize) {
      const d3xScaleyScaleHasNullValue = hasNullValues(listblocks?.mapSize)
      if (d3xScaleyScaleHasNullValue === true) {
        if (listblocks?.blockTtData) {
          const piersMinMax = findMinMax(listblocks?.blockTtData?.piers);
          const ttMinMax = findMinMax(listblocks?.blockTtData?.tt);
          setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
        }
      }
      else {
        setD3xScaleyScale({ piers: listblocks.mapSize?.piers, tt: listblocks.mapSize?.tt })
      }
    }

  }, [listblocks?.mapSize])

  useEffect(() => {
    if (listblocks.blockTtData) {
      sectionCallFn()
    }
  }, [listblocks?.blockTtData])

  const sectionCallFn = async () => {
    try {
      await setSectionList({ data: listblocks.blockTtData.piers })
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    trackerSubmit()
  }, [selectedRow])

  const trackerSubmit = async () => {
    let temp = []
    let hightlightData = []
    selectedRow.map((i) => {
      temp.push(i.split("-")[1])
      hightlightData.push(parseInt(i.split("-")[2]))
    })
    setHightLightTracker(hightlightData.filter((item, index) => hightlightData.indexOf(item) === index))
  }

  useEffect(() => {
    let trackerSort = []
    let prt = []
    if (listblocks?.blocksTracker) {
      listblocks?.blocksTracker?.map((i) => {
        if (handleCheckZimmerman()) {
          prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}`)
        }
        else {
          prt.push(`${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]}`)
        }
      })

      prt = [...new Set(prt)]

      prt.map((i) => {
        let trName = {}
        trName.name = i
        let tcount = 0
        listblocks?.blocksTracker?.map((j) => {
          if (handleCheckZimmerman()) {
            if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`) {
              tcount += j?.count
            }
          }
          else {
            if (`${i}` === `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`) {
              tcount += j?.count
            }
          }
        })
        trName.count = tcount
        trackerSort.push(trName)
        listblocks?.blocksTracker?.map((j) => {
          let trackerName
          if (handleCheckZimmerman()) {
            trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}`
          }
          else {
            trackerName = `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]}`
          }
          if (`${i}` === trackerName) {
            let newtrName = {}
            newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
            newtrName.count = j?.count
            newtrName.id = j.tracker_type_id
            trackerSort.push(newtrName)
          }
        })
      })
    }
    setTrackerList(trackerSort)
  }, [listblocks?.blocksTracker])

  const navigate = useNavigate()

  const [modalSecTracIsOpen, setSecTracIsOpen] = useState(false)

  const openSecModal = async () => {
    try {
      setBomLoading(true)
      let tSelectedRow = selectedRow.map((row) => {
        return parseInt(row.split("-")[1])
      })
      let tSelectedRowNo = selectedRow.map((row) => {
        return parseInt(row.split("-")[3])
      })
      let tSelectedSec = selectedRow.map((row) => {
        return parseInt(row.split("-")[4])
      })
      if (selectedRow.length !== 0) {
        await setFetchSelectedTrackerBOM({ block_id: parseInt(params.id), selected_rows: tSelectedRow, tSelectedSec: tSelectedSec, selected_rowsNo: tSelectedRowNo });
      }
      setSecTracIsOpen(true)
      setBomLoading(false)
    }
    catch (err) {
      console.log(err)
      toast.error("Something went wrong")
    }
  }

  function afterOpenSecTracModal() {
  }

  function closeSecTracModal() {
    setSecTracIsOpen(false);
  }

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const openModal = async () => {
    try {
      setBomLoading(true)
      await setFetchBomBlock({ block_id: params.id })
      setIsOpen(true);
      setBomLoading(false)
    }
    catch (err) {
      toast.error("Something went wrong")
      console.log(err)
    }
  }

  function afterOpenModal() {
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeTrackerModal() {
    setTrackerModal(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80vw',
      maxHeight: "650px",
      padding: "0px 20px 20px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const trackersCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '800px',
      maxHeight: "650px",
      padding: "0px 20px 20px"
    },
    overlay: {
      backgroundColor: "rgba(0,0,0,.7)",
    }
  }

  const handleClear = () => {
    setClearSt(!clearSt)
    setSelectAll(false)
    setSectionSelect([])
    setTrackerSelect([])
    setPrevSection(null)
    setPrevTracker(null)
  }

  let block_name = listblocks?.blocks.find((i) => i.id === parseInt(params.id))

  // let listblocks?.bomBlock = _.sortBy(listblocks?.bomBlock, [function (o) { return o.part_name }])

  // let listblocks?.selectedTrackersBom = _.sortBy(listblocks?.selectedTrackersBom, [function (o) { return o.part_name }])

  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  const date = new Date();
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);

  const formattedDate = `${day}-${month}-${year}`;

  function handleCheckDownloadData() {
    let result = false
    let temp = new Set()
    for (let pr in listblocks?.blockTtData.piers) {
      for (let pierIndex in listblocks?.blockTtData.piers[pr]) {
        let pierData = listblocks?.blockTtData.piers[pr][pierIndex]
        temp.add(`trow-${pr}-${pierData.tracker_type_id}-${pierData.row}-${pierData.section}`)
      }
    }
    if (selectedRow.length === Array.from(temp).length) {
      result = true
    }
    return result
  }

  const handleDownloadBlock = async () => {
    try {
      toast("Download queued")
      let arr = []
      listblocks?.bomBlock.map((i) => {
        let temp = {}
        temp["Part Name"] = i.part_name?.toUpperCase()
        temp["Description"] = i.description
        temp["Block Qty"] = i.project_quantity_calc
        temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`
        temp["Distributed Quantity"] = i.distributed_quantity
        temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`
        temp["Installed Quantity"] = i.installed_quantity
        arr.push(temp)
      })
      let newFields = [
        "Part Name",
        "Description",
        "Required Qty",
        "Distribution Progress %",
        "Distributed Quantity",
        "Installed Progress %",
        "Installed Quantity"
      ]
      let opts = { newFields }
      const parser = new Parser(opts)
      let csv = parser.parse(arr)
      csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", ${block_name?.name} BOM Status\n` + `"Report Date", ${formattedDate}\n` + `"Block", ${block_name?.name}\n` + `\n` + csv
      const element = document.createElement("a")
      const file = new Blob([csv], { type: 'text/csv' })
      element.href = URL.createObjectURL(file)
      element.download = `${project_name?.project_number}_BOM for ${block_name?.name}_${project_name?.name}`
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    catch (err) {
      console.log(err)
      toast.error("Download failed")
    }
  }

  const handleDownloadSelectedTracker = async () => {
    try {
      toast("Download queued")
      let arr = []
      listblocks?.selectedTrackersBom.map((i) => {
        if (i?.project_quantity_calc) {
          let temp = {}
          temp["Section"] = i.section
          temp["Tracker Row"] = i.row_no
          temp["Part Name"] = i.part_name?.toUpperCase()
          temp["Description"] = i.description
          temp["Required Qty"] = i.project_quantity_calc
          temp["Distribution Progress %"] = `${roundOff(i.distribution_progress)}%`
          temp["Distributed Quantity"] = i.distributed_quantity
          temp["Installed Progress %"] = `${roundOff(i.installed_progress)}%`
          temp["Installed Quantity"] = i.installed_quantity
          arr.push(temp)
        }
      })
      let newFields = [
        "Section",
        "Tracker Row",
        "Part Name",
        "Description",
        "Required Qty",
        "Distribution Progress %",
        "Distributed Quantity",
        "Installed Progress %",
        "Installed Quantity"
      ]
      let opts = { newFields }
      const parser = new Parser(opts)
      let csv = parser.parse(arr)
      csv = `"Project Number", ${project_name?.project_number}\n` + `"Project Name", ${project_name?.name}\n` + `"Report", Selected Tracker BOM\n` + `"Report Date", ${formattedDate}\n` + `"Block", ${block_name?.name}\n` + `\n` + csv
      const element = document.createElement("a")
      const file = new Blob([csv], { type: 'text/csv' })
      element.href = URL.createObjectURL(file)
      element.download = `${project_name.project_number}_Selected Trackers BOM_${project_name.name}`
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    catch (err) {
      console.log(err)
      toast.error("Download failed")
    }
  }

  const handleProgressChange = async (e) => {
    if (e === "All" || e === "None Selected") {
      setOverallProgress(e)
      localStorage.setItem("selected-activity", e)
    }
    else {
      localStorage.setItem("selected-activity", e[0])
      setOverallProgress(e[0])
    }
    try {
      setCountLoading(true)
      if (e === "None Selected") {
        await setActivityId(e)
      }
      if (e !== "None Selected") {
        let temp = []
        selectedRow.map((i) => {
          temp.push(i.split("-")[1])
        })
        if (e === "All") {
          await setActivityId(e)
          await setDeployOverallCount({ block_id: params.id, tracker: temp })
        }
        else {
          await setActivityId(e[0])
          if (listId?.construction) {
            await setDeployCount({ activity_id: e[0], part_id: listId?.construction[e[0]]?.part_id, block_id: params.id, tracker: temp })
          }
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setCountLoading(false)
    }
  }

  function getConstructionprogressTabActivityStatus() {
    let result = "Construction Progress"
    if (overallProgress !== "All" && overallProgress !== "None Selected") {
      if (listId?.construction) {
        if (listId?.construction[overallProgress] && listId?.construction[overallProgress]?.parent_activity_id) {
          let parent_activity_id = listId?.construction[overallProgress]?.parent_activity_id
          if (parent_activity_id && listId?.construction[parent_activity_id]) {
            result = listId?.construction[parent_activity_id]?.name
          }
        }
      }
    }
    return result
  }

  function getTrackerWiseDatas() {
    let temp = {}
    selectedRow.map((i) => {
      let tempKey = parseInt(i.split("-")[2])
      let obj = temp[findTrackerName(tempKey)]
      if (!obj) {
        temp[findTrackerName(tempKey)] = 1
      }
      if (obj) {
        temp[findTrackerName(tempKey)] = temp[findTrackerName(tempKey)] + 1
      }
    })
    return temp
  }

  const findTrackerName = (data) => {
    let name = listblocks?.trackerName.find(k => k?.id === data)
    return name?.tracker_type
  }

  let CPparActIds = []
  let QCparActIds = []

  if (listblocks?.overallCP) {
    for (let cp in listblocks?.overallCP) {
      if (listId?.construction && !listId.construction[cp]?.parent_activity_id) {
        CPparActIds.push(cp)
      }
    }
  }

  if (listblocks.blocksQC) {
    listblocks.blocksQC.map((qc) => {
      if (listId?.qc && !listId.qc[qc.activity_id]?.parent_activity_id) {
        QCparActIds.push(qc.activity_id)
      }
    })
  }

  let finalCPArr = []
  let finalQCArr = []

  CPparActIds.map((pt) => {
    finalCPArr.push(parseInt(pt))
    if (listId?.construction) {
      Object.values(listId?.construction)?.map((ij) => {
        if (ij.parent_activity_id === parseInt(pt)) {
          finalCPArr.push(ij.id)
        }
      })
    }
  })

  QCparActIds.map((pt) => {
    finalQCArr.push(parseInt(pt))
    Object.values(listId?.qc)?.map((ij) => {
      if (ij.parent_activity_id === parseInt(pt)) {
        finalQCArr.push(ij.id)
      }
    })
  })

  const findCPprogressCalc = (data) => {
    let temp = 0
    if (listblocks.blocksCP) {
      listblocks.blocksCP.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    return temp
  }

  const findCPprogress = (data) => {
    let temp = 0
    if (listblocks.blocksCP) {
      listblocks.blocksCP.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    if (listId?.construction && listId?.construction[data] && listId?.construction[data]?.parent_activity_id) {
      return temp
    }
    else {
      let sub_weight_progress = []
      let sub_weight = []
      if (listId?.construction) {
        for (let actKey in listId?.construction) {
          if (listId?.construction && listId?.construction[actKey]?.parent_activity_id === data) {
            sub_weight_progress.push(listId.construction[actKey]?.weight_decimal * findCPprogress(parseInt(actKey)))
            sub_weight.push(listId.construction[actKey]?.weight_decimal)
          }
        }
      }
      let progressData = sub_weight_progress.reduce((partialSum, a) => partialSum + a, 0) / sub_weight.reduce((partialSum, a) => partialSum + a, 0)
      return progressData
    }
  }

  const findQCprogress = (data) => {
    let temp = 0
    if (listblocks.blocksQC) {
      listblocks.blocksQC.map((i) => {
        if (i.activity_id === data) {
          temp = i.progress
        }
      })
    }
    return temp
  }

  const ttlOverAllPercent = () => {
    let subList = []
    let subWight = []
    if (listId?.construction) {
      Object?.keys(listId?.construction)?.map((i) => {
        if (listId?.construction[i]?.parent_activity_id) {
          subWight.push(listId?.construction[i]?.weight)
          subList.push(listId?.construction[i]?.weight * findCPprogressCalc(parseInt(i)))
        }
      })
    }
    let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
    if (isNaN(temp)) {
      return 0
    }
    else {
      return temp
    }
  }

  const ttlOverAllQCPercent = () => {
    let subList = []
    let subWight = []
    if (listId.qc) {
      Object?.keys(listId?.qc)?.map((i) => {
        if (listId?.qc[i]?.parent_activity_id) {
          subWight.push(listId?.qc[i]?.weight)
          subList.push(listId?.qc[i]?.weight * findQCprogress(parseInt(i)))
        }
      })
    }
    let temp = roundOff((subList.reduce((a, b) => a + b, 0)) / (subWight.reduce((a, b) => a + b, 0)))
    if (isNaN(temp)) {
      return 0
    }
    else {
      return temp
    }
  }

  const handleSelectAll = () => {
    setSelectAll(!selectAll)
    if (listblocks?.sectionList) {
      setSectionSelect(listblocks?.sectionList)
      setPrevSection(null)
    }
    setPrevTracker(null)
    let temp = []
    trackerList.map((i) => {
      if (i?.id) {
        temp.push(i?.id)
      }
    })
    setTrackerSelect(temp)
  }

  useEffect(() => {
    let temp = {}
    selectedRow.map((i) => {
      if (isNaN(temp[i.split("-")[2]])) {
        temp[i.split("-")[2]] = 1
      }
      else {
        temp[i.split("-")[2]] = temp[i.split("-")[2]] + 1
      }
    })
    trackerList.map((i) => {
      if (temp[i?.id] === i?.count) {
        if (!trackerSelect.includes(i.id)) {
          setTrackerSelect(produce((draft) => { draft.push(i.id) }))
        }
      }
      else if (temp[i?.id] !== i?.count && trackerSelect.includes(i.id) && temp[i?.id]) {
        let index = trackerSelect.findIndex(o => o === i?.id)
        setTrackerSelect(produce((draft) => { draft.splice(index, 1) }))
      }
      else if (trackerSelect.includes(i?.id) && !temp[i?.id]) {
        let tempData = [...trackerSelect]
        let data = tempData.filter(o => o?.id === i?.id)
        setTrackerSelect(data)
      }
    })
  }, [selectedRow])

  const handleSectionSelect = (data) => {
    if (sectionSelect.includes(data)) {
      setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
      setPrevSection(data)
    }
    else {
      setSectionSelect(produce((draft) => { draft.push(data) }))
      setPrevSection(null)
    }
  }

  const handleTrackerRowSelection = (dataTT) => {
    if (trackerSelect.includes(dataTT.id)) {
      setTrackerSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === dataTT.id), 1) }))
      setPrevTracker(dataTT.id)
    }
    else {
      setTrackerSelect(produce((draft) => { draft.push(dataTT.id) }))
      setPrevTracker(null)
    }
  }

  return (
    <Container>
      <div className='flex justify-between overflow-auto w-full h-full'>
        <div className="mr-2 h-full" style={{ width: showProgress ? "calc(100vw - 465px)" : "100%" }}>
          <div className='my-3 flex justify-between'>
            <div className='flex pt-1.5'>
              <BiArrowBack className='cursor-pointer mx-5 mt-0.5' style={{ width: "20px", height: "20px" }} onClick={() => navigate("/dashboard")} />
              <p className='font-semibold'>{block_name?.name}</p>
            </div>
            <div className='flex items-center'>
              <div className='mr-1 ml-2' onClick={() => setTrackerModal(true)}>
                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0}>
                  {handleCheckZimmerman() ? "View Selected Tables" : "View Selected Trackers"}
                </p>
              </div>
              <Modal
                isOpen={openTrackerModal}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeTrackerModal}
                style={trackersCustomStyles}
                contentLabel='Tracker items'
              >
                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                  <p className='mt-2'>
                    {handleCheckZimmerman() ? `Selected Table(s)` : `Selected Tracker(s)`}
                  </p>
                  <div className='flex'>
                    <VscClose onClick={closeTrackerModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                  </div>
                </div>
                <div>
                  <div className='mb-3 w-[45%]'>
                    {Object.keys(getTrackerWiseDatas()).map((i) => {
                      return (
                        <div className='flex items-center justify-between text-sm'>
                          <p className='text-base my-1' style={{ textTransform: "capitalize" }}>{i}</p>
                          <div className='flex space-x-5 w-[20%]'>
                            <p className='text-base font-medium my-1'>-</p>
                            <p className='text-sm my-1'>{getTrackerWiseDatas()[i]}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='flex'>
                    <p className='font-bold text-xl w-[36%] pb-2' style={{ top: "76px" }}>Total</p>
                    <div className='flex space-x-5'>
                      <p className='text-base font-medium'>-</p>
                      <p className='text-base ml-2'>{selectedRow.length} {handleCheckZimmerman() ? `table(s)` : `tracker(s)`}</p>
                    </div>
                  </div>
                  <table className={`table table-compact z-0 text-center overflow-y-auto`} style={{ width: "100%" }}>
                    <thead>
                      {handleCheckZimmerman() ?
                        <tr className='sticky z-20' style={{ top: "75px" }}>
                          <th className='normal-case'>Row</th>
                          <th className='normal-case'>Table</th>
                          <th className='normal-case'>Table Type</th>
                        </tr>
                        :
                        <tr className='sticky z-20' style={{ top: "75px" }}>
                          <th className='normal-case'>Section</th>
                          <th className='normal-case w-[60%]'>Tracker Row</th>
                          <th className='normal-case'>Tracker Type</th>
                        </tr>
                      }
                    </thead>
                    <tbody>
                      {selectedRow.map((i) => {
                        return (
                          <tr>
                            <td>{i.split("-")[4]}</td>
                            <td>{i.split("-")[3]}</td>
                            <td className={`${handleCheckZimmerman() ? "" : "text-left"}`} style={{ textTransform: "capitalize" }}>{findTrackerName(parseInt(i.split("-")[2]))}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Modal>
              <div className='flex items-center ml-2 mr-1'>
                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' onClick={handleSelectAll}>Select All</p>
              </div>
              <div className='flex items-center ml-2 mr-1'>
                <p className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' disabled={!selectedRow?.length > 0} onClick={handleClear}>Clear Selection</p>
              </div>
              <div className='text-xl border bg-gray-200 ml-2'>
                {showProgress ?
                  <MdKeyboardArrowRight className="cursor-pointer" onClick={() => setShowProgress(false)} />
                  :
                  <MdKeyboardArrowLeft className="cursor-pointer" onClick={() => setShowProgress(true)} />
                }
              </div>
            </div>
          </div>
          <hr />
          <div className='flex justify-between ml-5'>
            <div className='flex mb-5 w-[78%]'>
              <div className='flex mr-10 mt-5'>
                <div className='bg-gray-50 rounded-l-3xl border pl-3' style={{ width: "225px", height: "84px" }}>
                  <p className='text-xs pt-5 pl-4'>
                    {getConstructionprogressTabActivityStatus()}
                  </p>
                  {listId?.construction ?
                    <ActivitySelectBox
                      defaultValue={localStorage.getItem("selected-activity") ? localStorage.getItem("selected-activity") : overallProgress}
                      placeholder="Select an Activity"
                      className="pl-4 py-1.5 bg-gray-50 rounded focus:outline-none focus:border-gray-300 w-44 font-bold text-sm mt-1"
                      Overall={true}
                      NoneSelectedActivity={true}
                      page="Dashboard"
                      activities={listId?.construction}
                      activities_disabled={false}
                      sub_activities_disabled={false}
                      multiselect={false}
                      onChange={handleProgressChange}
                    />
                    :
                    null
                  }
                </div>
                <p className='bg-gray-50 rounded-r-3xl border pt-7 pl-5 font-bold text-red-550 text-xl' style={{ width: "80px", height: "84px" }}>
                  {!loading ?
                    overallProgress === "All" ?
                      <>{ttlOverAllPercent()}%</>
                      :
                      overallProgress === "None Selected" ?
                        "-"
                        :
                        <>{roundOff(findCPprogress(parseInt(overallProgress)))}%</>

                    :
                    <p className='loader mt-1' style={{ width: "25px", height: "25px" }}></p>}
                </p>
              </div>
              <div className='flex border border-white mt-5'>
                <div className='bg-gray-50 rounded-l-3xl border-r' style={{ width: "150px", height: "84px" }}>
                  <p className='pl-7 pt-5 text-xs'>Counts</p>
                  <p className=' pt-2 pl-7 text-sm font-bold' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    {countLoading ?
                      <p className='loader' style={{ width: "15px", height: "15px" }}></p>
                      :
                      overallProgress === "All" || overallProgress === "None Selected" ?
                        <p className='font-bold text-xl ml-3'>-</p>
                        :
                        listPartsAtom.map((j) => {
                          return <>
                            {listId?.construction && j?.id === listId?.construction[overallProgress]?.part_id ?
                              j?.part_name
                              :
                              null
                            }
                          </>
                        })
                    }
                  </p>
                </div>
                {countLoading ?
                  <div className='bg-gray-50 rounded-r-3xl' style={{ width: "180px", height: "84px" }}>
                    <p className='loader m-auto mt-7' style={{ width: "25px", height: "25px" }}></p>
                  </div>
                  :
                  <div>
                    {overallProgress === "All" || overallProgress === "None Selected" ?
                      <div className='flex font-bold text-xl pl-10 text-red-550 bg-gray-50 rounded-r-3xl' style={{ width: "180px", height: "84px" }}>
                        <p className='pt-7'>-</p>
                        <p className='px-3 text-4xl pt-5'>/</p>
                        <p className='pt-7'>-</p>
                      </div>
                      :
                      <div className='flex font-bold text-xl justify-center px-5 min-w-[180px] text-red-550 bg-gray-50 rounded-r-3xl' style={{ height: "84px" }}>
                        <p className='pt-7'>{listblocks?.count?.deployed_part_count && listblocks?.count?.deployed_part_count[0]?.deployed_count !== null ? listblocks?.count?.deployed_part_count[0]?.deployed_count.toLocaleString() : "-"}</p>
                        <p className='px-3 text-4xl pt-5'>/</p>
                        <p className='pt-7'>{listblocks?.count?.total_part_count?.toLocaleString()}</p>
                      </div>
                    }
                  </div>}
              </div>
              <div className='ml-5 mt-5'>
                <p className='text-gray-400 text-sm font-semibold'>Section</p>
                {loading ?
                  <p className='loader m-auto mt-2' style={{ width: "25px", height: "25px" }}></p>
                  :
                  <div className='flex flex-wrap justify-left text-center max-w-[200px] max-h-[70px] overflow-y-auto'>
                    {listblocks?.sectionList.map((section) => {
                      return (
                        <p className={`cursor-pointer m-1 w-10 py-1 text-xs ${sectionSelect?.includes(section) ? "bg-red-550 text-white border-red-550 border" : "bg-white text-red-550 border"}`} onClick={() => handleSectionSelect(section)}>{section}</p>
                      )
                    })}
                  </div>
                }
              </div>
            </div>
            {!handleCheckZimmerman() &&
              <div className='flex items-center flex-wrap justify-end space-x-4 w-[22%]'>
                <SelectBoxView onChange={(e) => setShowTT(e)} flag={project_name?.project_settings} />
                <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm' style={{ marginTop: "-5px" }} id="download">
                  <CgSoftwareDownload className='mr-1' />Download
                </button>
              </div>
            }
          </div>

          {loading ?
            <div style={{ marginTop: "20%", paddingLeft: "50%", width: "calc(100vw - 705px)" }}>
              <p className='loader ml-5 mb-1'></p>
              <p className='font-bold text-xl'>Fetching...</p>
            </div>
            :
            <div className='cursor-grab m-auto' style={{ width: "98%", height: "66vh" }}>
              <BlockMap
                zimmerMan={handleCheckZimmerman()}
                deviceType="web"
                bulkAllData={listblocks?.blockTtData}
                pierLegends={listblocks?.pierLegends}
                type="CP"
                typeData={listblocks.cpTrackerRowWise}
                userSelection={{ showType: showTT, selection: listblocks.selectedActivity }}
                currentBlock={listblocks.blocks?.find((i) => i?.id === parseInt(params.id))}
                currentProjectData={projectsList.find(o => o.id === parseInt(localStorage.getItem("project_id")))}
                downloadD3={true}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                showLegend={true}
                mapSize={d3xScaleyScale}
                selectAll={selectAll}
                section={sectionSelect}
                prevSection={prevSection}
                trackerSelect={trackerSelect}
                prevTracker={prevTracker}
                clearSelection={clearSt}
                controls={true}
              />
            </div>
          }

        </div>
        {showProgress && <div className='border-x h-full'>
          <div className="overflow-auto h-full pr-2" style={{ height: "calc(100vh - 100px)", width: "445px" }}>
            <div className='flex text-blue-500 font-semibold text-sm m-6'>
              <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="BOM status"
              >
                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                  <p className='mt-2'>{block_name?.name} - Bill of Materials</p>
                  <div className='flex'>
                    <div className='flex font-semibold text-sm mr-10 mt-1 cursor-pointer' onClick={handleDownloadBlock}>
                      <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
                      <p className='text-gray-500 ml-1.5'>Download</p>
                    </div>
                    <VscClose onClick={closeModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                  </div>
                </div>
                <div className='flex'>
                  <table className={`table table-compact z-0 w-[100%]`}>
                    <thead>
                      <tr className='sticky z-20' style={{ top: "76px" }}>
                        <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
                        <th className='normal-case' rowSpan='2' style={{ width: "45%" }}>Part Description</th>
                        <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Block Qty</th>
                        <th className='normal-case' colspan="2" style={{ width: "13%" }}>Distributed</th>
                        <th className='normal-case' colspan="2" style={{ width: "13%" }}>Installed</th>
                      </tr>
                      <tr className='sticky z-20' style={{ top: "112.5px" }}>
                        <th className='normal-case'>%</th>
                        <th className='normal-case'>Qty</th>
                        <th className='normal-case'>%</th>
                        <th className='normal-case'>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listblocks?.bomBlock.map((i) => {
                        return (
                          <tr className='hover'>
                            <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                            <td className='pr-12 relative' style={{ width: "22vw" }} onMouseEnter={() => { setShowDescription(i.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                              {showDescription === i?.bom_id ?
                                <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                  {i?.description}
                                </p>
                                :
                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "22vw" }}>{i.description}</p>
                              }
                            </td>
                            <td>{i?.project_quantity_calc}</td>
                            <td>
                              {roundOff(i?.distribution_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                                :
                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                  :
                                  <p style={{ color: "black" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                              }
                            </td>
                            <td>
                              {roundOff(i?.distribution_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                                :
                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                  :
                                  <p style={{ color: "black" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                              }
                            </td>
                            <td>
                              {roundOff(i?.installed_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                                :
                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                  :
                                  <p style={{ color: "black" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                              }
                            </td>
                            <td>
                              {roundOff(i?.installed_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                                :
                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#de7eb1" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                  :
                                  <p style={{ color: "black" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                              }
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Modal>
              <div className='flex'>
                {selectedRow && selectedRow?.length > 0 ?
                  <>
                    {handleCheckDownloadData() ?
                      <div className='mr-10' onClick={openModal}>
                        <div className='flex cursor-pointer'>
                          <p>BOM Status for {block_name?.name}</p>
                          {bomLoading ?
                            <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                            :

                            <BsBoxArrowUpRight alt='' className='ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                          }
                        </div>
                      </div>
                      :
                      <div onClick={openSecModal}>
                        <div className='flex cursor-pointer'>
                          {handleCheckZimmerman() ?
                            <p>BOM Status for Selected Tables</p>
                            :
                            <p>BOM Status for Selected Trackers</p>
                          }
                          {bomLoading ?
                            <p className='loader ml-1' style={{ width: "20px", height: "20px" }}></p>
                            :
                            <BsBoxArrowUpRight className=' ml-1 text-black' style={{ width: '17px', height: '17px' }} />
                          }
                        </div>
                      </div>
                    }
                  </>
                  :
                  <div className='text-gray-300'>
                    <div className='flex'>
                      {handleCheckZimmerman() ?
                        <p>BOM Status for Selected Tables</p>
                        :
                        <p>BOM Status for Selected Trackers</p>
                      }
                      <BsBoxArrowUpRight className='ml-1' style={{ width: '17px', height: '17px' }} />
                    </div>
                  </div>
                }
                {/* <button className='cursor-pointer text-xs rounded-xl border-none bg-red-550 text-white px-4 py-1 btn btn-sm ml-5' style={{marginTop: "-5px"}} id="download"><CgSoftwareDownload className='mr-1' />Download</button> */}
              </div>
              <Modal
                isOpen={modalSecTracIsOpen}
                onAfterOpen={afterOpenSecTracModal}
                onRequestClose={closeSecTracModal}
                style={customStyles}
                contentLabel="BOM status For Selected Trackers"
              >
                <div className='flex justify-between font-bold text-xl py-5 bg-white sticky top-0 z-20'>
                  {handleCheckZimmerman() ?
                    <p className='mt-2'>{block_name?.name} - Selected Tables - Bill of Materials</p>
                    :
                    <p className='mt-2'>{block_name?.name} - Selected Trackers - Bill of Materials</p>
                  }
                  <div className='flex'>
                    <div className='flex font-semibold text-sm mr-10 mt-1 cursor-pointer' onClick={handleDownloadSelectedTracker}>
                      <CgSoftwareDownload className='text-gray-500' style={{ width: '20px', height: '20px' }} />
                      <p className='text-gray-500 ml-1.5'>Download</p>
                    </div>
                    <VscClose onClick={closeSecTracModal} className="cursor-pointer active:bg-red-550 active:text-white hover:bg-gray-200 rounded-full" style={{ width: "25px", height: '25px' }} />
                  </div>
                </div>
                <table className="table table-compact z-0 overflow-auto w-full" >
                  <thead>
                    <tr className='sticky z-20' style={{ top: "76px" }}>
                      <th className='normal-case' colspan='2' style={{ width: "14%" }}>
                        {handleCheckZimmerman() ?
                          "Selected Tables"
                          :
                          "Selected Trackers"
                        }
                      </th>
                      <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Part Number</th>
                      <th className='normal-case' rowSpan='2' style={{ width: "36%" }}>Part Description</th>
                      <th className='normal-case' rowSpan='2' style={{ width: "15%" }}>Required Qty</th>
                      <th className='normal-case' colspan="2" style={{ width: "10%" }}>Distributed</th>
                      <th className='normal-case' colspan="2" style={{ width: "10%" }}>Installed</th>
                    </tr>
                    <tr className='sticky z-20' style={{ top: "112.5px" }}>
                      <th className='normal-case'>
                        {handleCheckZimmerman() ?
                          "Row"
                          :
                          "Section"
                        }
                      </th>
                      <th className='normal-case'>
                        {handleCheckZimmerman() ?
                          "Table"
                          :
                          "Tracker Row"
                        }
                      </th>
                      <th className='normal-case'>%</th>
                      <th className='normal-case'>Qty</th>
                      <th className='normal-case'>%</th>
                      <th className='normal-case'>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listblocks?.selectedTrackersBom.map((i) => {
                      if (i?.project_quantity_calc) {
                        return (
                          <tr className="hover">
                            <td>{i?.section}</td>
                            <td>{i?.row_no}</td>
                            <td style={{ textTransform: 'uppercase' }}>{i?.part_name?.toUpperCase()}</td>
                            <td className='pr-12 relative' style={{ width: "20vw" }} onMouseEnter={() => { setShowDescription(i?.bom_id) }} onMouseLeave={() => { setShowDescription() }}>
                              {i?.bom_id && showDescription === i?.bom_id ?
                                <p className='border px-5 absolute bg-white top-2' style={{ textTransform: "capitalize", boxShadow: "2px 5px #c2c0c0" }}>
                                  {i?.description}
                                </p>
                                :
                                <p style={{ textTransform: 'capitalize', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "20vw" }}>{i?.description}</p>
                              }
                            </td>
                            <td>{i?.project_quantity_calc}</td>
                            <td>
                              {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distribution_progress)}%</p>
                                :
                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distribution_progress * 100) / 100}%</p>
                                  :
                                  <p style={{ color: "black" }}>{i?.distribution_progress ? roundOff(i?.distribution_progress * 100) / 100 : 0}%</p>
                              }
                            </td>
                            <td>
                              {i?.distribution_progress && roundOff(i?.distribution_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.distributed_quantity)}</p>
                                :
                                roundOff(i?.distribution_progress * 100) / 100 > 0 && roundOff(i?.distribution_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.distributed_quantity * 100) / 100}</p>
                                  :
                                  <p style={{ color: "black" }}>{i?.distributed_quantity ? roundOff(i?.distributed_quantity * 100) / 100 : 0}</p>
                              }
                            </td>
                            <td>
                              {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_progress)}%</p>
                                :
                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_progress * 100) / 100}%</p>
                                  :
                                  <p style={{ color: "black" }}>{i?.installed_progress ? roundOff(i?.installed_progress * 100) / 100 : 0}%</p>
                              }
                            </td>
                            <td>
                              {i?.installed_progress && roundOff(i?.installed_progress) === 100 ?
                                <p style={{ backgroundColor: "#B4F8D7" }}>{roundOff(i?.installed_quantity)}</p>
                                :
                                roundOff(i?.installed_progress * 100) / 100 > 0 && roundOff(i?.installed_progress * 100) / 100 < 100 ?
                                  <p style={{ backgroundColor: "#FC8EAC" }}>{roundOff(i?.installed_quantity * 100) / 100}</p>
                                  :
                                  <p style={{ color: "black" }}>{i?.installed_quantity ? roundOff(i?.installed_quantity * 100) / 100 : 0}</p>
                              }
                            </td>
                          </tr>
                        )
                      }

                    })}
                  </tbody>
                </table>
              </Modal>
            </div>

            <div className='border rounded-xl ml-6 p-3' style={{ width: '400px' }}>
              <div>
                <div className='flex justify-between font-bold text-base'>
                  <p>Construction Progress</p>
                  <p className='text-red-550'>
                    {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                      {ttlOverAllPercent()}%</>}
                  </p>
                </div>
                <div className='font-medium text-xs'>
                  {loading ?
                    <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                      <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                      <p className='font-medium text-sm'>Fetching...</p>
                    </div>
                    :
                    finalCPArr.map((i) => {
                      return (
                        <div className='flex justify-between mt-3'>
                          {listId.construction[i]?.parent_activity_id ?
                            <p className='flex pl-4 w-[155px]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                            :
                            <p className='font-extrabold w-[155px]'>{listId?.construction ? listId?.construction[i]?.name : i}</p>
                          }
                          <div className='flex justify-between' style={{ width: '215px' }}>
                            <progress className={`progress w-40 mt-1 ${findCPprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findCPprogress(i)} max="100" />
                            <p>{roundOff(findCPprogress(i))}%</p>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>

            <div className={`border rounded-xl ml-6 mt-6 p-3`} style={{ width: '400px' }}>
              <div>
                <div className='flex justify-between font-bold text-base'>
                  <p>Quality Check Progress</p>
                  <p className='text-red-550'>
                    {loading ? <p className='loader' style={{ width: "15px", height: "15px" }}></p> : <>
                      {qcCheck() ? ttlOverAllQCPercent() : 0}%</>}
                  </p>
                </div>
                {qcCheck() ?
                  <div className='font-medium text-xs'>
                    {loading ?
                      <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                        <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                        <p className='font-medium text-sm'>Fetching...</p>
                      </div>
                      :
                      finalQCArr.map((i) => {
                        return <>
                          <div className='flex justify-between mt-3'>
                            {listId.qc[i]?.parent_activity_id ?
                              <p className='flex pl-4 w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                              :
                              <p className='font-extrabold w-[155px]'>{listId?.qc ? listId?.qc[i]?.name : i}</p>
                            }
                            <div className='flex justify-between' style={{ width: '215px' }}>
                              <progress className={`progress w-40 mt-1 ${findQCprogress(i) === 100 ? "progress-success" : "progress-error"}`} value={findQCprogress(i)} max="100"></progress>
                              <p>{roundOff(findQCprogress(i))}%</p>
                            </div>
                          </div>
                        </>
                      })
                    }
                  </div>
                  :
                  null
                }
              </div>
            </div>
            <div className='border rounded-xl ml-6 mt-6 p-3' style={{ width: '400px' }}>
              <div>
                <div className='flex justify-between font-bold text-base'>
                  {handleCheckZimmerman() ?
                    <p>Table Type</p>
                    :
                    <p>Tracker Type</p>
                  }
                  <p>Quantities</p>
                </div>
                <div className='font-medium text-xs'>
                  {loading ?
                    <div style={{ marginTop: "2%", marginLeft: "45%" }}>
                      <p className='loader ml-3 mb-1' style={{ width: "25px", height: "25px" }}></p>
                      <p className='font-medium text-sm'>Fetching...</p></div>
                    :
                    trackerList?.map((i, index) => {
                      return (
                        <div key={index} className='flex justify-between mt-4'>
                          {i?.name.split("+_+").length === 2 ?
                            <p className={`ml-4 px-4 py-1 cursor-pointer ${hightLightTracker.includes(i?.id) ? "border-red-550 border-2" : "border"} ${trackerSelect.includes(i?.id) ? "bg-red-550 text-white" : "border"}`} onClick={() => handleTrackerRowSelection(i)}>
                              {i?.name.split("+_+")[0]}
                            </p>
                            :
                            <p className='font-extrabold'>{i?.name}</p>
                          }
                          <p className='mr-1'>{i?.count}</p>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    </Container >
  )
}

export default Dashboard2
