import produce from 'immer'
import { useAtom } from 'jotai'
import React, { useEffect, useState } from 'react'
import { MdDelete } from 'react-icons/md'
import Modal from 'react-modal';
import qcAtom, { createUpdateApplyQc, createUpdateQcCheckpoints, fetchPierLegends, fetchPierTtParts, fetchQcTrackerChecklist, fetchTrackerTypes, fetchTypeTracker } from '../../../atoms/QcChecklistAtom/qcAtom'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { RiEdit2Fill } from 'react-icons/ri';
import { unstable_usePrompt } from 'react-router-dom/dist';
import MultipleValueTextInput from 'react-multivalue-text-input'
import ReactTooltip from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import QcBlock from './qcBlock';

function Tab({ currentTab, text, editMode, onClick }) {
    return (
        <div className='w-1/3 text-center h-full'>
            {editMode ?
                <p className={`text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`}>{text}</p>
                :
                <p className={`cursor-pointer text-base h-full flex justify-center font-semibold items-center border-r border-white ${currentTab === text ? "" : "bg-red-100"}`} onClick={() => onClick(text)}>{text}</p>
            }
        </div>
    )
}

function QcTrackerChecklist() {

    const params = useParams()

    const [listQcData] = useAtom(qcAtom)

    const [, setFetchTrackers] = useAtom(fetchTrackerTypes)
    const [, setCreateUpdateQc] = useAtom(createUpdateQcCheckpoints)
    const [, setFetchQcData] = useAtom(fetchQcTrackerChecklist)
    const [, setFetchPierTtParts] = useAtom(fetchPierTtParts)
    const [, setFetchPierLegends] = useAtom(fetchPierLegends)
    const [, setCreateUpdateApplyQc] = useAtom(createUpdateApplyQc)
    const [, setMapType] = useAtom(fetchTypeTracker)

    const [loading, setLoading] = useState({ boolean: false, data: "Fetching..." })
    const [editMode, setEditMode] = useState(false)
    const [checklistData, setChecklistData] = useState({ tab: "Piers", status: 0, project_mapped_checklist: false, type: 0, lable: "", checklist_id: null, design_pier_ht: "", custom_inputs: [], data: [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }] })
    const [selectedItem, setSeletedItem] = useState()
    const [dropdownModal, setDropdownModal] = useState({ boolean: false, index: null, values: { values: [] } })
    const [fieldConfModal, SetFieldConfModal] = useState({ boolean: false, index: null, changedValue: null })


    const QcTabs = ["Piers", "Structure", "Module"]

    useEffect(() => {
        init()
    }, [params.id, checklistData.tab])

    useEffect(() => {
        setChecklistData(produce((draft) => { draft.tab = QcTabs[0]; draft.type = 1 }))
        setEditMode(false)
        handleTrackerLoad()
    }, [params.id])

    unstable_usePrompt({
        when: editMode,
        message: "You are leaving the page without completing the edits, the Checkpoint will revert to last saved version"
    })

    const init = async () => {
        try {
            setLoading({ boolean: true, data: "Fetching..." })
            await setFetchPierLegends()
            await setFetchPierTtParts({ tracker_type: parseInt(params.id), tab: checklistData.tab, tracker: false })
            await setMapType()
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading({ boolean: false, data: "Fetching..." })
        }
    }

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // subtitle.style.color = '#f00';
    }

    const customConfirmStyle = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px',
            boxShadow: "3px 3px 10px 1px"
        },
        overlay: {
            backgroundColor: "rgba(0,0,0,.7)",
        }
    }

    const handleTrackerLoad = async () => {
        try {
            await setFetchTrackers()
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleChangePart = async (e) => {
        console.log(e)
        try {
            setLoading({ boolean: true, data: "Fetching..." })
            setSeletedItem(e)
            console.log(listQcData?.typeMode, params.id, checklistData.tab.toLowerCase())
            console.log(listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`], 0, editMode)
            if ((listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === 0) && !editMode) {
                setChecklistData(produce((draft) => { draft.data = [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }]; draft.type = 1 }))
                await setFetchQcData({ tab: checklistData.tab, tracker_type_id: parseInt(params.id), selectedItem: e })
            }
            if ((listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === undefined)) {
                setChecklistData(produce((draft) => { draft.data = [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }]; draft.type = 1 }))
                await setFetchQcData({ tab: checklistData.tab, tracker_type_id: parseInt(params.id), selectedItem: e })
            }
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading({ boolean: false, data: "Fetching..." })
        }
    }

    const handleToggleType = async (id) => {
        try {
            if (editMode) {
                setChecklistData(produce((draft) => { draft.type = id }))
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        let partIt
        if (listQcData.trackerDataPierTT?.piers && listQcData.trackerDataPierTT?.tt) {
            if (checklistData.tab === QcTabs[0]) {
                let pierData = listQcData.trackerDataPierTT.piers[Object.keys(listQcData.trackerDataPierTT.piers)[0]]
                console.log([...pierData].sort((a, b) => a.latitude - b.latitude))
                partIt = [...pierData].sort((a, b) => a.latitude - b.latitude)[0]?.pier_type_id
            }
            else {
                let ttData = listQcData.trackerDataPierTT.tt[Object.keys(listQcData.trackerDataPierTT.tt)[0]]
                partIt = [...ttData].sort((a, b) => a.latitude - b.latitude)[0]?.tt_type_id
            }
            setSeletedItem(partIt)
        }
    }, [listQcData.trackerDataPierTT?.piers, listQcData.trackerDataPierTT?.tt])

    function fetchChecklistDatas() {
        let customInputs = [
            {
                custom_input_type: 0,
                custom_input_values: { values: [] },
                custom_input_name: "Custom Label 1"
            },
            {
                custom_input_type: 0,
                custom_input_values: { values: [] },
                custom_input_name: "Custom Label 2"
            },
            {
                custom_input_type: 0,
                custom_input_values: { values: [] },
                custom_input_name: "Custom Label 3"
            }
        ]
        let labelName = ""
        if (checklistData.type) {
            if (checklistData.tab === QcTabs[0]) {
                labelName = listQcData?.trackerDataPierTT.piers[Object.keys(listQcData?.trackerDataPierTT.piers)[0]].filter(o => o.id === selectedItem)[0]?.pier_part_label
            }
            else {
                labelName = listQcData?.trackerDataPierTT.tt[Object.keys(listQcData?.trackerDataPierTT.tt)[0]].filter(o => o.id === selectedItem)[0]?.tt_part_label
            }
        }
        else {
            labelName = ""
        }
        let mapType
        if (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === 0) {
            mapType = listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`]
        }
        else {
            mapType = 1
        }
        if (listQcData.checklistData[0]) {
            setChecklistData(produce((draft) => {
                draft.type = mapType
                // draft.type = parseInt(listQcData.checklistData[0]?.checklist_status)
                draft.data = listQcData.checklistData[0]?.checkpoints.length > 0 ? listQcData.checklistData[0]?.checkpoints : [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.project_mapped_checklist = listQcData.checklistData[0]?.project_mapped_checklist
                draft.checklist_id = listQcData.checklistData[0]?.checklist_id;
                draft.lable = listQcData.checklistData[0]?.checklist_name ? listQcData.checklistData[0]?.checklist_name : labelName;
                draft.custom_inputs = listQcData.checklistData[0]?.custom_inputs ? listQcData.checklistData[0]?.custom_inputs : []
            }))
        }
        else {
            setChecklistData(produce((draft) => {
                draft.type = mapType;
                draft.data = [{ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }];
                draft.checklist_id = null;
                draft.lable = labelName;
                draft.project_mapped_checklist = false
                draft.custom_inputs = []
            }))
        }
    }

    useEffect(() => {
        fetchChecklistDatas()
    }, [listQcData.checklistData[0], listQcData.typeMode])

    const handleChangetab = (e) => {
        setChecklistData(produce((draft) => { draft.tab = e }))
        let partIt
        if (listQcData.trackerDataPierTT?.piers && listQcData.trackerDataPierTT?.tt) {
            if (e === QcTabs[0]) {
                partIt = listQcData.trackerDataPierTT?.piers[Object.keys(listQcData.trackerDataPierTT?.piers)[0]][0]?.id
            }
            else {
                partIt = listQcData.trackerDataPierTT?.tt[Object.keys(listQcData.trackerDataPierTT?.tt)[0]][0]?.id
            }
            setSeletedItem(partIt)
        }
    }

    const handleSaveApply = async (applyStatus) => {
        try {
            setLoading({ boolean: true, data: "Updating..." })
            let newId = false
            console.log(parseInt(listQcData.checklistData[0]?.checklist_status), checklistData.type)
            if (listQcData.checklistData[0] && parseInt(listQcData.checklistData[0]?.checklist_status) !== checklistData.type) {
                newId = true
            }
            await setCreateUpdateApplyQc({ tracker_type_id: parseInt(params.id), checklistData: checklistData, applyStatus: applyStatus.applyStatus, newId: newId })
            await setFetchQcData({ tab: checklistData.tab, tracker_type_id: parseInt(params.id), selectedItem: selectedItem })
            await setMapType()
            toast.success("Created Successfully")
            setEditMode(false)
        }
        catch (err) {
            console.log(err)
            toast.err("Something went wrong")
        }
        finally {
            setLoading({ boolean: false, data: "Updating..." })
        }
    }

    const onChecklistSubmit = async () => {
        try {
            setLoading({ boolean: true, data: "Updating..." })
            let newId = false
            if (listQcData.checklistData[0] && parseInt(listQcData.checklistData[0]?.checklist_status) !== checklistData.type) {
                newId = true
            }
            await setCreateUpdateQc({ checklistData: checklistData, selectedItem: selectedItem, newId: newId, tracker_type_id: parseInt(params.id), projectLevel: false, itr: true })
            await setFetchQcData({ tab: checklistData.tab, tracker_type_id: parseInt(params.id), selectedItem: selectedItem })
            await setMapType()
            toast.success("Created Successfully")
            setEditMode(false)
        }
        catch (err) {
            console.log(err)
            toast.error("Something went wrong")
        }
        finally {
            setLoading({ boolean: false, data: "Updating..." })
        }
    }

    const handleCancel = () => {
        setEditMode(false)
        fetchChecklistDatas()
        if (listQcData.checklistData[0]) {
            setChecklistData(produce((draft) => { draft.type = parseInt(listQcData.checklistData[0]?.checklist_status) }))
        }
        else {
            setChecklistData(produce((draft) => { draft.type = 1 }))
        }
    }

    const handleAddCheckPoint = () => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.data.push({ checkpoint_name: "", acceptance_criteria: "", inspection_type: "" }) }))
        }
    }

    const handleRemoveCheckpoint = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => { draft.data[index]["delete"] = true }))
        }
    }

    const handleFindTrackerName = () => {
        let temp = listQcData.trackerDatas.find(o => o.id === parseInt(params.id))
        return temp?.tracker_type
    }

    const handleMultiInputSubmit = () => {
        console.log(dropdownModal.values)
        setChecklistData(produce((draft) => { draft.custom_inputs[dropdownModal.index].custom_input_values = dropdownModal.values }))
        setDropdownModal({ boolean: false, index: null, values: { values: [] } })
    }

    const handleFindInputType = (data) => {
        let temp
        if (data === 0) {
            temp = "Integer"
        }
        if (data === 1) {
            temp = "Float"
        }
        if (data === 2) {
            temp = "Free Text"
        }
        if (data === 3) {
            temp = "Dropdown"
        }
        return temp
    }

    const handleChangeDropDown = (value, index) => {
        if (value === 3) {
            setDropdownModal({ boolean: true, index: 0, values: { values: checklistData.custom_inputs[index]?.custom_input_values?.values ? checklistData.custom_inputs[index]?.custom_input_values?.values : [] } })
        }
        setChecklistData(produce((draft) => { draft.custom_inputs[index].custom_input_type = value }))
    }

    const handleAddCustom = () => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                draft.custom_inputs.push({
                    custom_input_type: 0,
                    custom_input_values: {},
                    custom_input_name: "Custom Label"
                })
            }))
        }
    }

    const handleDeleteCustomFields = (index) => {
        if (editMode) {
            setChecklistData(produce((draft) => {
                // draft.custom_inputs.splice(index, 1)
                draft.custom_inputs[index]["delete"] = true
            }))
        }
    }

    const handleCheckCountCustomFields = () => {
        let temp = []
        checklistData?.custom_inputs.map((i) => {
            if (!i?.delete) {
                temp.push(i)
            }
        })
        return temp
    }

    const handleChangeFields = (e, index) => {
        if (parseInt(e.target.value) === 3) {
            setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.custom_input_values?.values ? checklistData.custom_inputs[index]?.custom_input_values?.values : [] } })
        }
        setChecklistData(produce((draft) => { draft.custom_inputs[index].custom_input_type = parseInt(e.target.value) }))
    }

    const handleFieldConfirmCancel = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].custom_input_type = 3 }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    const handleFieldConfirm = () => {
        setChecklistData(produce((draft) => { draft.custom_inputs[fieldConfModal.index].custom_input_type = fieldConfModal.changedValue }))
        SetFieldConfModal({ boolean: false, index: null, changedValue: null })
    }

    if (loading.boolean) {
        return (
            <div className='flex flex-col justify-center items-center w-full h-full' style={{ height: "calc(100vh - 200px)" }}>
                <p className='loader mb-1'></p>
                <p className='font-bold text-lg ml-3'>{loading.data}</p>
            </div>
        )
    }

    return (
        <div className='w-full flex h-full'>
            <div className={`w-[100px] border pt-[120px]`}>
                {listQcData?.trackerDataPierTT?.piers && listQcData?.trackerDataPierTT?.tt &&
                    // <QcBlock
                    //     editMode={editMode}
                    //     changePart={!editMode && (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === undefined)}
                    //     data={listQcData.pierTtParts}
                    //     lockAll={checklistData.type}
                    //     pierData={listQcData?.pierTtParts?.pier_parts}
                    //     torqueTubeData={listQcData?.pierTtParts?.torque_tube_parts}
                    //     pierLegend={listQcData?.pierLegends}
                    //     selectedTab={checklistData.tab}
                    //     onSelectItem={e => handleChangePart(e)}
                    //     selectedItem={selectedItem}
                    // />
                    <QcBlock
                        editMode={editMode}
                        changePart={!editMode && (listQcData?.typeMode[`${params.id}_${checklistData.tab.toLowerCase()}`] === undefined)}
                        data={listQcData.trackerDataPierTT}
                        lockAll={checklistData.type}
                        pierLegend={listQcData?.pierLegends}
                        selectedTab={checklistData.tab}
                        onSelectItem={e => handleChangePart(e)}
                        selectedItem={selectedItem}
                    />
                }

            </div>
            <div className='h-full' style={{ width: "calc(100% - 100px)" }}>
                <div className='flex justify-between space-x-5 py-3'>
                    <div className='flex'>
                        <p className='text-base font-medium px-4 flex items-center'>{handleFindTrackerName()}</p>
                        <div className={`flex text-sm cursor-pointer border rounded-full font-medium p-1 ${!editMode ? "border-gray-100 bg-gray-50" : "border-red-100 bg-red-50"} mr-4`}>
                            <div className={`flex ${checklistData.type ? "" : `${!editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full`}`}>
                                <p className={`${checklistData.type ? "text-gray-400 pr-3 pl-2 py-2 pt-2" : "text-white pr-3 pl-2 py-2 pt-2"} mr-1`} onClick={() => handleToggleType(0)}>Combined</p>
                            </div>
                            <div className={`flex ${checklistData.type ? `${!editMode ? "bg-gray-500" : "bg-red-550"} border-none rounded-full` : ""}`}>
                                <p className={`${checklistData.type ? "text-white pr-3 pl-2 py-2 pt-2" : "text-gray-400 pr-3 pl-2 py-2 pt-2"} ml-1`} onClick={() => handleToggleType(1)}>Individual {checklistData.tab === QcTabs[0] ? "Piers" : "Bays"}</p>
                            </div>
                        </div>
                    </div>
                    {editMode ?
                        <div className='bg-white flex items-center space-x-5 px-5 justify-end'>
                            <button className='border-none btn bg-red-550 text-white rounded-full px-4' disabled={checklistData.type === 0} onClick={() => handleSaveApply({ applyStatus: true })}>
                                {checklistData.tab === QcTabs[0] ?
                                    "Save and Apply to all Piers"
                                    :
                                    "Save and Apply to all Bays"
                                }
                            </button>
                            <button className='border-none btn bg-red-550 text-white rounded-full px-4' onClick={checklistData.type === 0 ? () => handleSaveApply({ applyStatus: false }) : onChecklistSubmit}>Save</button>
                            <button className='btn border-2 cursor-pointer px-4 rounded-full bg-white text-gray-500 hover:text-white' onClick={() => handleCancel()}>Cancel</button>
                        </div>
                        :
                        <div className='bg-white z-10 flex items-center space-x-5 px-5'>
                            <button className='btn border-2 rounded-full bg-white px-5 cursor-pointer text-gray-500 hover:text-white' onClick={() => setEditMode(true)}>Edit</button>
                        </div>
                    }
                </div>
                <hr />
                <div className={`flex justify-around border-b w-full h-[40px]`}>
                    {QcTabs.map((tab) => {
                        return (
                            <Tab currentTab={checklistData.tab} text={tab} editMode={editMode} onClick={e => {
                                if (!editMode) {
                                    handleChangetab(e)
                                }
                            }} />
                        )
                    })}
                </div>
                <div className={`w-full overflow-auto ${editMode ? "" : "bg-gray-100 cursor-auto"}`} style={{ height: "calc(100vh - 195px)" }}>
                    <div className='w-full'>
                        {checklistData.type ?
                            <div className='flex justify-between px-5 items-center space-y-3 w-[55%]'>
                                <p className='text-base font-bold'>Label</p>
                                <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[55%]' value={checklistData.lable} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.lable = e.target.value }))} />
                            </div>
                            :
                            null
                        }
                        <div className='w-full'>
                            <div className='w-[50%] flex items-center space-x-8 mb-3 mt-5 justify-between'>
                                <p className='text-base font-bold w-[45%] pl-6 flex'>
                                    Field Name
                                    <span data-tip data-for="QC field name" className='ml-2 mt-1' >
                                        <AiOutlineInfoCircle className='cursor-pointer' />
                                    </span>
                                </p>
                                <ReactTooltip backgroundColor='black' place="bottom" clickable effect="solid" id="QC field name">
                                    <p className='w-[200px]'>Use these custom labels and fields to set the required input fields from site as they do QC, like recording pier heights, plant identifier etc.,</p>
                                </ReactTooltip>
                                <p className='text-base font-bold w-[40%] pl-3'>Field Type</p>
                            </div>
                            {checklistData?.custom_inputs.map((i, index) => {
                                if (!i?.delete) {
                                    return (
                                        <div className='flex space-x-8 px-5 items-center space-y-3'>
                                            <div className='w-[50%] flex items-center space-x-8 space-y-3 justify-between'>
                                                <div className='flex space-x-2 items-center w-[45%]'>
                                                    <input className={`text-base font-medium border-2 rounded-xl px-4 py-2 mt-2 flex items-center w-full focus:outline-none ${!editMode && "cursor-pointer"}`} disabled={!editMode} value={i?.custom_input_name} name="label_01" onChange={e => setChecklistData(produce((draft) => { draft.custom_inputs[index].custom_input_name = e.target.value }))} />
                                                    <RiEdit2Fill className='text-xl mt-1' />
                                                </div>
                                                <select required disabled={!editMode} className={`px-4 py-2 rounded-xl select-bordered border-2 w-[40%] ${editMode ? "cursor-pointer" : "bg-gray-100"}`} onChange={e => handleChangeFields(e, index)}>
                                                    <option selected={checklistData.custom_inputs[index]?.custom_input_type === 0} value={0}>Integer</option>
                                                    <option selected={checklistData.custom_inputs[index]?.custom_input_type === 1} value={1}>Float</option>
                                                    <option selected={checklistData.custom_inputs[index]?.custom_input_type === 2} value={2}>Free Text</option>
                                                    <option selected={checklistData.custom_inputs[index]?.custom_input_type === 3} value={3}>Dropdown</option>
                                                </select>
                                                <MdDelete className='cursor-pointer' onClick={() => handleDeleteCustomFields(index)} />
                                            </div>
                                            <div style={{ display: checklistData.custom_inputs[index]?.custom_input_type === 3 ? "" : "none" }}>
                                                <p className={`${editMode ? "cursor-pointer underline underline-offset-2 text-blue-500" : "text-gray-500"} text-sm`} onClick={() => { editMode && setDropdownModal({ boolean: true, index: index, values: { values: checklistData.custom_inputs[index]?.custom_input_values ? checklistData.custom_inputs[index]?.custom_input_values : [] } }) }}>View & edit dropdown options</p>
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {handleCheckCountCustomFields().length < 3 && <p className='cursor-pointer flex ml-5 w-[40%] my-2' onClick={handleAddCustom}>+ Add more</p>}
                            <Modal
                                isOpen={fieldConfModal.boolean}
                                style={customConfirmStyle}
                                contentLabel='Type Confirmation'
                            >
                                <div className='p-2'>
                                    <p className='mb-4'>The dropdown options added already will get deleted, please confirm</p>
                                    <div className='flex justify-end space-x-5 font-semibold'>
                                        <p className='cursor-pointer text-gray-500' onClick={handleFieldConfirmCancel}>Cancel</p>
                                        <p className='cursor-pointer text-red-550' onClick={handleFieldConfirm}>Confirm</p>
                                    </div>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={dropdownModal.boolean}
                                style={customConfirmStyle}
                                contentLabel='MultipleValueTextInput'
                            >
                                <div className='p-3 w-[500px]'>
                                    <p className='font-medium text-base mb-2'>{checklistData.custom_inputs[dropdownModal.index]?.custom_input_name}</p>
                                    <MultipleValueTextInput
                                        onItemAdded={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                        onItemDeleted={(item, allItems) => setDropdownModal(produce((draft) => { draft.values = { values: allItems } }))}
                                        name="item-input"
                                        className={"w-[40%] p-1 mt-5 border-b-2"}
                                        placeholder="Add the required drop down options and press Enter"
                                        values={checklistData.custom_inputs[dropdownModal.index]?.custom_input_values?.values ? checklistData.custom_inputs[dropdownModal.index]?.custom_input_values?.values : []}
                                    />
                                    <div className='flex space-x-5 justify-end my-3'>
                                        <p className='cursor-pointer font-medium text-gray-500' onClick={() => setDropdownModal({ boolean: false, index: null, values: { values: [] } })}>Cancel</p>
                                        <button className='cursor-pointer font-medium text-red-550' onClick={handleMultiInputSubmit}>Save</button>
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    </div>
                    <div className='w-full mt-6'>
                        <div className='flex justify-between px-5 mb-2'>
                            <p className='font-bold w-[30%]'>Checkpoint</p>
                            <p className='font-bold w-[30%]'>Acceptance Criteria</p>
                            <p className='font-bold w-[30%]'>Inspection Type</p>
                            <p></p>
                        </div>
                        <hr />
                        <div className='w-full'>
                            {checklistData.data && checklistData.data.map((formData, formIndex) => {
                                if (!formData.delete) {
                                    return (
                                        <div className='w-full flex justify-between px-5 my-4' key={`${formIndex}`}>
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.checkpoint_name} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].checkpoint_name = e.target.value }))} />
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.acceptance_criteria} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].acceptance_criteria = e.target.value }))} />
                                            <input disabled={!editMode} className='border rounded-xl px-4 py-2 w-[30%]' required value={formData.inspection_type} placeholder='Free Text' onChange={e => setChecklistData(produce((draft) => { draft.data[formIndex].inspection_type = e.target.value }))} />
                                            {/* <p className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)}>D</p> */}
                                            <p><MdDelete className='cursor-pointer text-xl mt-3' onClick={() => handleRemoveCheckpoint(formIndex)} /></p>
                                        </div>
                                    )
                                }
                            })}
                            <p className='cursor-pointer mx-5 text-base font-bold' onClick={handleAddCheckPoint}>+ Add More</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QcTrackerChecklist
